import request from "./request.js";
// import { formatQuery } from "./index";

// 添加店铺
export function AddStore(params) {
    return request({
        url: `addStore`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 添加Banner图片
export function AddBanner(params) {
    return request({
        url: `addBannerbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 编辑Banner图片
export function EditBanner(params) {
    return request({
        url: `editBannerbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 删除Banner图片
export function DelBanner(params) {
    return request({
        url: `delBannerbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取Banner图片列表
export function GetBannerList(params) {
    return request({
        url: `getBannerListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 添加分类
export function AddCategory(params) {
    return request({
        url: `addModCategorybyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 编辑分类
export function EditCategory(params) {
    return request({
        url: `editModCategorybyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 删除分类
export function DelCategory(params) {
    return request({
        url: `delModCategorybyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取分类列表
export function GetCategoryList(params) {
    return request({
        url: `getModCategoryListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取产品列表
export function GetProductList(params) {
    return request({
        url: `getProductListbyCategory`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取用户列表
export function GetUserSearchList(params) {
    return request({
        url: `getUserSearchListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取用户详情
export function GetUserDetail(params) {
    return request({
        url: `getUserDetailbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 批量删除用户
export function DelUserList(params) {
    return request({
        url: `delUserListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 修改用户
export function EditUser(params) {
    return request({
        url: `editUserbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 添加推荐
export function AddRecomm(params) {
    return request({
        url: `addRecommbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 编辑推荐
export function EditRecomm(params) {
    return request({
        url: `editRecommbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取推荐列表
export function GetRecommList(params) {
    return request({
        url: `getRecommListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取订单列表（新）
export function GetPayOrderListByAdmin(params) {
    return request({
        url: `getPayOrderListByAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取订单列表
export function GetOrderList(params) {
    return request({
        url: `getOrderListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 账单订单生成pdf文件
export function GetPayOrderGeneratePdf(params) {
    return request({
        url: `getPayOrderGeneratePdf`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取帐单列表
export function GetPayOrderDetail(params) {
    return request({
        url: `getPayOrderDetail`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 确认订单
export function UpdateOrderbyAdmin(params) {
    return request({
        url: `updateOrderbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 添加促销代码
export function AddCoupons(params) {
    return request({
        url: `addCoupons`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 删除促销代码
export function DelCoupons(params) {
    return request({
        url: `delCoupons`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取用户ID
export function GetUserDetailToPhoneOrEmail(params) {
    return request({
        url: `getUserDetailToPhoneOrEmail`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取用户数据
export function GetUsersCountlyCountry(params) {
    return request({
        url: `getUsersCountlyCountry`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取中国仓库数据
export function GetUserChinaAddress(params) {
    return request({
        url: `getUserChinaAddress`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取促销代码
export function GetCouponsList(params) {
    return request({
        url: `getCouponsList`,
        method: "POST",
        data: params,
        auth: 1,
    });
}


// 获取历年销售额
export function GetYearMonthlySales(params) {
    return request({
        url: `getYearLastYearMonthlySales`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取订单详情
export function GetOrderDetail(params) {
    return request({
        url: `getOrderDetailbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 核查确定预付款已到账
export function ConfirmPrepayment(params) {
    return request({
        url: `confirmPrepaymentAttachbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 核查确定预付款未到账
export function ConfirmNoPrepayment(params) {
    return request({
        url: `prepaymentRollbackAttachbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 核查确定尾款已到账
export function ConfirmBalance(params) {
    return request({
        url: `confirmBalanceAttachbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 核查确定尾款未到账
export function ConfirmNoBalance(params) {
    return request({
        url: `waitBalanceRollbackAttachbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 取消订单
export function CanceOrder(params) {
    return request({
        url: `canceOrderbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 产品数量
export function GetAllProductCount(params) {
    return request({
        url: `getAllUserOfProductCount`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

export function AddExchateRate(params) {
    return request({
        url: `addExchateRatebyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取汇率列表
export function GetExchateRateList(params) {
    return request({
        url: `getExchateRateListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 产品数量
export function GetCategroyProductCount(params) {
    return request({
        url: `getCategroyProductCount`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 修改汇率
export function EditExchateRate(params) {
    return request({
        url: `editExchateRatebyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 产品数量
export function GetMonthProductCount(params) {
    return request({
        url: `getMonthApenProductCount`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 用户数据
export function GetUserRise(params) {
    return request({
        url: `getUserCountbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 删除汇率
export function DelExchateRate(params) {
    return request({
        url: `delExchateRatebyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// admin登录
export function Login(params) {
    return request({
        url: `loginbyAdmin`,
        method: "POST",
        data: params,
        auth: 0,
    })
}

// 获取oss直传凭证
export function GetOssPolicyToken(params) {
    return request({
        url: `getOssPolicyToken`,
        method: "POST",
        data: params,
        auth: 0,
    })
}

// 添加本地oss文件
export function AddOssFile(params) {
    return request({
        url: `addOssFilebyAdmin`,
        method: "POST",
        data: params,
        auth: 0,
    })
}

// 获取分类列表
export function GetMyCategoryList(params) {
    return request({
        url: `getCategoryList`,
        method: "POST",
        data: params,
        auth: 0,
    })
}

// 获取国家列表
export function GetCountryList(params) {
    return request({
        url: `getCountryList`,
        method: "POST",
        data: params,
        auth: 0,
    })
}

// 获取商户列表
export function GetVendorSearchList(params) {
    return request({
        url: `getVendorSearchListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}


// 修改店铺状态
export function EditStorebyAdmin(params) {
    return request({
        url: `editStorebyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取订单支付列表
export function GetOrderPayList(params) {
    return request({
        url: `getOrderPayListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    })
}

// 获取店铺详情
export function GetStore(params) {
    return request({
        url: `getStorebyAdmin`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取产品列表 修改 auth: 1,
export function GetSpuList(params) {
    return request({
        url: `getSpuListbyAdmin`,
        method: "POST",
        data: params,
        auth: 1,
    });
}

// 获取操作记录详情
export function GetRecord(params) {
    return request({
        url: `getRecordbyAdmin`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取操作记录列表
export function GetRecordList(params) {
    return request({
        url: `getRecordListbyAdmin`,
        method: "POST",
        data: params,
        auth: 0,
    });
}

// 获取店铺下的用户列表
export function GetUserListbyStoreId(params) {
    return request({
        url: `getUserListbyStoreIdbyAdmin`,
        method: "POST",
        data: params,
        auth: 0,
    });
}