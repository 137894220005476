<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Number of users</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
              Number of users :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0"><i class="el-icon-download"></i> {{ userCount }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { GetUsersCountlyCountry } from "@/utils/api";
export default {
  data() {
    return {
      series: [],
      userCount:0,
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [5, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: [
          "Senegal",
          "Unspecified country",
          "Guinea",
          "France",
          "Germany",
          "Italy",
          "China",
          "Belgium",
          "Ivory Coast",
          "Canada",
          "Mauritania",
          "Morocco",
          "Kenya",
          "Mali",
          "Burkina Faso",
          "Gambia The",
          "United States",
          "Cameroon",
          "Togo",
          "Spain",
          "Ghana",
          "United Kingdom",
          "Benin",
          "Gabon",
          "Democratic Republic of the Congo",
          "South Africa",
          "Afghanistan",
          "India",
          "Guinea-Bissau",
          "Serbia",
          "Nigeria",
          "Switzerland",
          "Congo",
          "Reunion",
          "Sweden",
          "Portugal",
          "Sierra Leone",
          "Ethiopia",
          "Rwanda",
          "Liberia",
          "Tanzania",
          "Netherlands",
          "Angola",
          "Greece",
          "Saudi Arabia",
          "Turkey",
          "Niger",
          "Anguilla",
          "Luxembourg",
          "Finland",
          "Guatemala",
          "Tunisia",
          "Qatar",
          "Aruba",
          "Mozambique",
          "Zimbabwe",
          "Uganda",
          "American Samoa",
          "Botswana",
          "Burundi",
          "Algeria",
          "Mauritius",
          "Martinique",
          "Madagascar",
          "Antarctica",
          "Djibouti",
          "Saint Vincent And The Grenadines",
          "Mayotte",
          "Ireland",
          "Norway",
          "Sao Tome and Principe",
          "Aland Islands",
          "Belize",
          "Samoa",
          "Georgia",
          "Zambia",
          "Bosnia and Herzegovina",
          "Oman",
          "Sénégalais",
          "Thailand",
          "Egypt",
          "French Guiana",
          "Costa Rica",
          "Cyprus",
          "Bahrain",
          "Antigua And Barbuda",
          "Argentina",
          "Cote D'Ivoire (Ivory Coast)",
          "Andorra",
          "Albania",
        ],
      },
    };
  },
  created() {
    this.GetSalesData();
  },
  methods: {
    GetSalesData() {
        GetUsersCountlyCountry().then((res) => {
        this.labels = res.data.users.forEach((item) => {
            this.userCount += item.user_count+8
        });
        this.series = [
            {
              name: "User",
              type: "line",
              data: res.data.users.map((item) =>item.user_count),
            },
          ];
      });
    },
  },
};
</script>

