<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
import Product from "./product.vue";
import RevenueAnalytics from "./revenue";
import UserCountry from "./userCountry";
import ChartsProductNew from "@/components/charts/product/new";
import ChartsProductEach from "@/components/charts/product/each";
import UserNumberRise from "@/components/charts/product/UserNumberRise";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    Product,
    UserCountry,
    RevenueAnalytics,
    ChartsProductNew,
    ChartsProductEach,
    UserNumberRise,
  },
  data() {
    return {
      title: this.$t("content.dashboard.text"),
      items: [
        {
          text: this.$t("common.home"),
        },
        {
          text: this.$t("content.dashboard.text"),
          active: true,
        },
      ],
      data: [],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Stat />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <RevenueAnalytics />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <UserCountry />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <Product />
      </div>
    </div>
        <charts-product-new />
        <charts-product-each />
        <user-number-rise />
  </Layout>
</template>